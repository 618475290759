<script>
import ReturnPointsForm from './ReturnPointsForm'

export default {
  extends: ReturnPointsForm,

  computed: {
    isBlocked() {
      return !!this.form.disabled_at
    },

    warningButtonText() {
      if (this.$store.user.role !== this.$constants.users.admin) {
        return null
      }

      return this.isBlocked ? 'Разблокировать': 'Заблокировать'
    },

    blockText() {
      return {
        title: `${this.isBlocked ? 'Разблокировать' : 'Заблокировать'} точку возврата?`,
        text:
          `Данное действие приведет к ${this.isBlocked ? 'разблокировке' : 'блокировке'} точки возврата. Вы уверены?`
      }
    }
  },

  created() {
    this.getReturnPoint(this.$route.params.id).then(response => {
      this.form = response
      this.startTime = response.delivery[0] && response.delivery[0].slice(0, -3)
      this.endTime = response.delivery[1] && response.delivery[1].slice(0, -3)
    })
  },

  methods: {
    onSubmit() {
      const formData = this.beforeSubmit()

      this.updateReturnPoint(this.form.id, formData)
        .then(() => {
          this.successSubmit({ message: 'Точка возврата успешно изменена' })
          this.goToList()
        })
        .finally(() => { this.afterSubmit() })
    },

    handleBlockClick() {
      this.isShowConfirmDialog = true
    },

    pointChangeStatus() {
      this.$apiMethods.returnPoints.block(this.form.id)
        .then(() => {
          this.successSubmit({ message: 'Точка возврата успешно изменена' })
          this.goToList()
        })
        .finally(() => { this.afterSubmit() })
    },

    goToList() {
      this.$router.push({
        name: 'return-points',
        params: { tradingNetworkId: this.$route.params.tradingNetworkId }
      })
    }
  }
}
</script>
